/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import {
  action, decorate, observable, runInAction, toJS
} from 'mobx';
import { InspectionService } from 'services';
import { DocumentHelper, FormHelper, IndexedDBHelper } from 'utils';
import { INPUT_TYPES, INSPECTION_SUBMIT_ACTIONS } from 'utils/constants';
import { referenceDataStore } from './ReferenceDataStore';

const INSPECTION_STORE = 'inspections';
const INSPECTION_KEY = 'inspection';
const INSPECTION_DETAIL_KEY = 'inspection_detail';
const INSPECTION_FORM_KEY = 'inspection_form';
const INSPECTION_SUBMIT_KEY = 'inspection_submit';

class InspectionStore {
  constructor() {
    this.isLoading = false;
    this.isLoadingForm = false;
    this.isDownloading = false;
    this.isSubmiting = false;
    this.isGeneratingReport = false;
    this.isGeneratingReportFromExcel = false;
    this.inspection = null;
    this.inspectionForm = null;
    this.inspectionId = null;
    this.folderId = null;
    this.isInspectionSaved = false;
    this.inspectionList = [];
    this.inspectionListStored = [];
    this.totalInspections = 0;
    this.maxPage = 0;
    this.startPosition = '';
    this.endPosition = '';
    this.reloadKey = '';
  }

  QUESTION_KEY({ sectionId, questionId }) {
    return `${INSPECTION_KEY}${this.inspectionId}_section${sectionId}_question${questionId}`;
  }

  setReloadKey(key) {
    runInAction('UpdateReloadKeys', () => {
      this.reloadKey = key;
    });
  }

  resetReloadKey() {
    runInAction('UpdateReloadKeys', () => {
      this.reloadKey = '';
    });
  }

  resetCurrentInspection() {
    return new Promise((resolve) => resolve(runInAction('ResetCurrentInspection', () => {
      this.inspection = null;
      this.inspectionForm = null;
      this.inspectionId = null;
      this.folderId = null;
      this.isInspectionSaved = false;
    })));
  }

  checkInspectionSaved({ folderId }) {
    return IndexedDBHelper.getData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_DETAIL_KEY}${folderId}`
    }).then((insp) => runInAction('SetIsInspectionSaved', () => {
      if (insp) {
        this.isInspectionSaved = true;
      } else {
        this.isInspectionSaved = false;
      }
    }));
  }

  getInspectionsToSubmit() {
    return IndexedDBHelper.getAllData({
      store: INSPECTION_STORE,
      range: IDBKeyRange.bound(INSPECTION_SUBMIT_KEY, `${INSPECTION_SUBMIT_KEY}\uffff`, false, false)
    }).then((inspList) => inspList);
  }

  loadInspectionListStored() {
    return IndexedDBHelper.getAllData({
      store: INSPECTION_STORE,
      range: IDBKeyRange.bound(INSPECTION_DETAIL_KEY, `${INSPECTION_DETAIL_KEY}\uffff`, false, false)
    }).then((inspList) => runInAction('LoadInspectionListStored', () => {
      this.inspectionListStored = inspList;
    }));
  }

  loadInspectionList({
    search = '', page = 0, filters = '', isOffline
  }) {
    if (isOffline) {
      return this.loadInspectionListStored();
    }

    this.isLoading = true;
    this.loadInspectionListStored();
    return InspectionService.getInspectionList({ search, filters, page })
      .then((inspList) => runInAction('LoadInspectionListFromBackend', () => {
        if (page === 0) {
          this.inspectionList = [];
        }

        this.inspectionList.push(...inspList.content);
        this.totalInspections = inspList.totalElements;
        this.maxPage = inspList.totalPages;
      }))
      .finally(() => runInAction('SetIsLoadingFalse', () => {
        this.isLoading = false;
      }));
  }

  loadInspectionFormStored() {
    return IndexedDBHelper.getData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_FORM_KEY}${this.inspectionId}`
    }).then((inspForm) => runInAction('LoadInspectionFormStored', () => {
      this.inspectionForm = inspForm;
      this.isLoadingForm = false;
    }));
  }

  loadInspectionStored({ folderId }) {
    return IndexedDBHelper.getData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_DETAIL_KEY}${folderId}`
    }).then((insp) => runInAction('LoadInspectionStored', () => {
      if (insp) {
        this.inspection = insp;
        this.folderId = insp.id;
        this.inspectionId = insp.inspectionId;

        this.loadInspectionFormStored();
      }

      return null;
    })).finally(() => runInAction('SetIsLoadingFalse', () => {
      this.isLoading = false;
    }));
  }

  loadInspection({ folderId, isOffline }) {
    this.isLoading = true;

    if (isOffline) {
      this.isLoading = false;
      return this.loadInspectionStored({ folderId });
    }

    return InspectionService.getInspection(folderId)
      .then((insp) => runInAction('LoadInspectionFromBackend', () => {
        this.inspection = insp;
        this.folderId = insp.id;
        this.inspectionId = insp.inspectionId;
        return insp;
      }))
      .catch((err) => {
        this.loadInspectionStored({ folderId });
        throw err;
      })
      .finally(() => runInAction('SetIsLoadingFalse', () => {
        this.isLoading = false;
      }));
  }

  loadInspectionForm({ inspectionId, isOffline }) {
    this.isLoadingForm = true;

    if (isOffline) {
      this.isLoadingForm = false;
      return null;
    }

    if (this.isInspectionSaved) {
      return this.loadInspectionFormStored();
    }

    return InspectionService.getInspectionForm(inspectionId)
      .then((inspForm) => runInAction('LoadInspectionFormFromBackend', () => {
        this.inspectionForm = {
          ...inspForm,
          surveySections: inspForm.surveySections.map((section) => ({
            ...section,
            surveyQuestions: section.surveyQuestions.map((ques) => ({
              ...ques,
              measured: FormHelper.getMeasuredValueFormatted(ques)
            }))
          }))
        };
      }))
      .catch((err) => { throw err; })
      .finally(() => runInAction('SetIsLoadingFormFalse', () => {
        this.isLoadingForm = false;
      }));
  }

  updateInspection(status) {
    return InspectionService.updateInspection(this.inspectionId, status);
  }

  reassignInspector(inspector) {
    return InspectionService.reassignInspector(this.inspectionId, inspector);
  }

  downloadInspectionOffline(folderId, status = 'IN_PROGRESS') {
    this.isDownloading = true;
    const statusOption = referenceDataStore.inspectionStatuses.find((is) => is.value === status);
    // Update inspection status
    return this.updateInspection(status)
      .then(() => {
        runInAction('UpdateInspectionStatus', () => {
          this.inspection.inspectionStatus = statusOption;
          this.inspection.isStored = true;
        });

        // Save inspection detail
        this.inspection && IndexedDBHelper.updateData({
          store: INSPECTION_STORE,
          key: `${INSPECTION_DETAIL_KEY}${folderId}`,
          data: toJS(this.inspection)
        })
          .then(() => runInAction('SetIsInspectionSaved', () => {
            this.isInspectionSaved = true;
          }))
          .catch((error) => { throw error; });

        // Save inspection form
        this.inspectionForm && IndexedDBHelper.updateData({
          store: INSPECTION_STORE,
          key: `${INSPECTION_FORM_KEY}${this.inspectionId}`,
          data: toJS(this.inspectionForm)
        })
          .catch((error) => { throw error; });
      })
      .catch((error) => { throw error; })
      .finally(() => runInAction('SetIsDownloadingFalse', () => {
        this.isDownloading = false;
      }));
  }

  saveQuestion({ key, question }) {
    return IndexedDBHelper.updateData({
      store: INSPECTION_STORE,
      data: question,
      key
    }).catch((error) => { throw error; });
  }

  saveValidationState(validationState) {
    return IndexedDBHelper.updateData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_KEY}${this.inspectionId}_validation_state`,
      data: validationState
    });
  }

  getValidationState() {
    return IndexedDBHelper.getData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_KEY}${this.inspectionId}_validation_state`
    });
  }

  saveInspectionTimestampAndPosition(updatedInspection) {
    IndexedDBHelper.updateData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_FORM_KEY}${this.inspectionId}`,
      data: updatedInspection
    });
  }

  saveInspectionFormOffline(status = 'IN_PROGRESS') {
    const saveEachQuestion = (inspForm) => new Promise((resolve, reject) => {
      inspForm.surveySections.forEach((section) => {
        section.surveyQuestions.forEach((question) => {
          this.saveQuestion({
            key: this.QUESTION_KEY({ sectionId: section.id, questionId: question.id }),
            question: toJS(question)
          }).catch((error) => { reject(error); });
        });
      });

      resolve();
    });

    return IndexedDBHelper.getData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_FORM_KEY}${this.inspectionId}`
    })
      .then((inspForm) => runInAction('SaveInspectionFormOffline', () => {
        if (inspForm) {
          if (!inspForm.startDate) {
            const today = new Date().toISOString();
            inspForm.startDate = today;
          }
          if (!inspForm.startPosition) {
            inspForm.startPosition = this.startPosition;
          }
          this.saveInspectionTimestampAndPosition(inspForm);
          this.inspectionForm = inspForm;
          this.isInspectionSaved = true;

          return saveEachQuestion(inspForm).then(() => inspForm);
        }

        // If the inspection was not already saved in the IndexedDB,
        // save it and restart the process
        return this.downloadInspectionOffline(this.folderId, status)
          .then(() => this.saveInspectionFormOffline(status));
      }));
  }

  saveInspectionFormCurrentStep({ step = 0 }) {
    return IndexedDBHelper.updateData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_KEY}${this.inspectionId}_formStep`,
      data: step
    }).catch((error) => { throw error; });
  }

  getInspectionFormCurrentStep() {
    return IndexedDBHelper.getData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_KEY}${this.inspectionId}_formStep`
    }).catch((error) => { throw error; });
  }

  getSectionQuestions({ sectionKey }) {
    return IndexedDBHelper.getAllData({
      store: INSPECTION_STORE,
      range: IDBKeyRange.bound(sectionKey, `${sectionKey}\uffff`, false, false)
    })
      .then((sectionList) => sectionList)
      .catch((error) => { throw error; });
  }

  getQuestion({ key }) {
    return IndexedDBHelper.getData({
      store: INSPECTION_STORE,
      key
    }).catch((error) => { throw error; });
  }

  deleteInspection({ folderId, inspectionId, deleteCurrent = true } = {}) {
    const deleteFolderId = folderId || this.folderId;
    const deleteInspectionId = inspectionId || this.inspectionId;

    // Delete all questions stored
    IndexedDBHelper.deleteAllData({
      store: INSPECTION_STORE,
      range: IDBKeyRange.bound(
        `${INSPECTION_KEY}${deleteInspectionId}`,
        `${INSPECTION_KEY}${deleteInspectionId}\uffff`,
        false,
        false
      )
    }).then(() => {
      if (deleteCurrent) {
        runInAction('DeleteCurrentInspection', () => {
          this.inspection = null;
          this.inspectionForm = null;
        });
      }
    });

    // Delete the form
    IndexedDBHelper.deleteData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_FORM_KEY}${deleteInspectionId}`
    }).catch((error) => { throw error; });

    // Delete the form step
    IndexedDBHelper.deleteData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_KEY}${deleteInspectionId}_formStep`
    }).catch((error) => { throw error; });

    // Delete the inspection detail
    return IndexedDBHelper.deleteData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_DETAIL_KEY}${deleteFolderId}`
    }).catch((error) => { throw error; });
  }

  deleteSubmittedInspection({ folderId }) {
    return IndexedDBHelper.deleteData({
      store: INSPECTION_STORE,
      key: `${INSPECTION_SUBMIT_KEY}${folderId}`
    }).catch((error) => { throw error; });
  }

  saveInspectionBeforeSubmit({
    submitAction, folderId, inspectionId, formCompleted
  }) {
    return IndexedDBHelper.updateData({
      store: INSPECTION_STORE,
      data: {
        submitAction,
        folderId,
        inspectionId,
        ...formCompleted
      },
      key: `${INSPECTION_SUBMIT_KEY}${folderId}`
    }).catch((err) => { throw err; });
  }

  getFormCompleted() {
    const formCompleted = toJS(this.inspectionForm);
    const allQuestions = [];

    this.inspectionForm.surveySections
      .map((section, indexSec) => section.surveyQuestions
        .map((question, indexQues) => allQuestions.push({
          key: this.QUESTION_KEY({ sectionId: section.id, questionId: question.id }),
          indexSec,
          indexQues
        })));

    const fillAllQuestions = allQuestions.map((ques) => this.getQuestion({
      key: ques.key
    }).then((quesIDB) => {
      if (quesIDB) {
        let measuredValue = quesIDB.measured;

        if (
          quesIDB.questionType.type === INPUT_TYPES.DOCUMENT
          || quesIDB.questionType.type === INPUT_TYPES.SIGNATURE
          || quesIDB.questionType.type === INPUT_TYPES.GPS
        ) {
          if (measuredValue.base64Content) {
            measuredValue = {
              ...measuredValue,
              base64Content: DocumentHelper.stripBase64(measuredValue.base64Content),
              editedBase64Content: DocumentHelper.stripBase64(measuredValue.editedBase64Content),
              creationDate: measuredValue.creationDate && new Date(measuredValue.creationDate)
            };
          } else if (measuredValue.images) {
            const images = measuredValue.images
              .filter((img) => img.base64Content)
              .map((img) => ({
                ...img,
                base64Content: DocumentHelper.stripBase64(img.base64Content),
                editedBase64Content: DocumentHelper.stripBase64(img.editedBase64Content),
                creationDate: img.creationDate && new Date(img.creationDate).toISOString()
              }));

            measuredValue = {
              ...measuredValue,
              images
            };
          }
        }

        formCompleted.surveySections[ques.indexSec].surveyQuestions[ques.indexQues] = {
          ...quesIDB,
          measured: measuredValue
        };
      }
    }));

    return Promise.all(fillAllQuestions).then(() => formCompleted);
  }

  submitInspection({ publish = false, validate = false, statusComment = '' } = {}) {
    this.isSubmiting = true;

    return this.getFormCompleted()
      .then((formCompleted) => {
        formCompleted.statusComment = statusComment;

        if (!formCompleted.endDate) {
          const today = new Date().toISOString();
          formCompleted.endDate = today;
        }

        if (!formCompleted.endPosition) {
          formCompleted.endPosition = this.endPosition;
        }

        const formCompletedForSave = formCompleted;
        if (validate) {
          // formCompleted.inspectionResult = isValidated;
          return InspectionService.validateInspection(this.inspectionId, formCompleted)
            .then(() => this.deleteInspection())
            /*
            .catch(() => {
              throw this.saveInspectionBeforeSubmit({
                submitAction: INSPECTION_SUBMIT_ACTIONS.VALIDATE,
                folderId: this.folderId,
                inspectionId: this.inspectionId,
                formCompleted
              });

              throw error;
            })
            */
            .finally(() => {
              runInAction('SetIsSubmitingFalse', () => {
                this.isSubmiting = false;
              });
            });
        }

        if (publish) {
          const today = new Date().toISOString();
          if (!formCompleted.endDate) {
            formCompleted.endDate = today;
          }

          if (!formCompleted.endPosition) {
            formCompleted.endPosition = this.endPosition;
          }

          formCompleted.realTimeEndDate = today;

          return InspectionService.publishInspection(this.inspectionId, formCompleted)
            .then(() => this.deleteInspection())
            /*
            .catch(() => {
              throw this.saveInspectionBeforeSubmit({
                submitAction: INSPECTION_SUBMIT_ACTIONS.PUBLISH,
                folderId: this.folderId,
                inspectionId: this.inspectionId,
                formCompleted
              });

              throw error;
            })
            */
            .finally(() => {
              runInAction('SetIsSubmitingFalse', () => {
                this.isSubmiting = false;
              });
            });
        }

        return InspectionService.saveInspection(this.inspectionId, formCompletedForSave)
          /*
          .catch(() => {
            throw this.saveInspectionBeforeSubmit({
              submitAction: INSPECTION_SUBMIT_ACTIONS.SAVE,
              folderId: this.folderId,
              inspectionId: this.inspectionId,
              formCompleted
            });

            throw error;
          })
          */
          .finally(() => runInAction('SetIsSubmitingFalse', () => {
            this.isSubmiting = false;
          }));
      })
      .catch((err) => { throw err; });
  }

  reSubmitInspection(inspToSubmit) {
    const {
      submitAction, inspectionId, folderId, ...formCompleted
    } = inspToSubmit;

    this.isSubmiting = true;

    if (formCompleted.endDate === null) {
      const today = new Date();
      formCompleted.endDate = today.toISOString();
    }

    if (formCompleted.endPosition === null) {
      formCompleted.endPosition = this.endPosition;
    }

    switch (submitAction) {
    case INSPECTION_SUBMIT_ACTIONS.VALIDATE:
      return InspectionService.validateInspection(inspectionId, formCompleted)
        .then(() => this.deleteInspection({ folderId, inspectionId }))
        .catch((err) => { throw err; })
        .finally(() => {
          runInAction('SetIsSubmitingFalse', () => {
            this.isSubmiting = false;
          });
        });
    case INSPECTION_SUBMIT_ACTIONS.PUBLISH:
      return InspectionService.publishInspection(inspectionId, formCompleted)
        .then(() => this.deleteInspection({ folderId, inspectionId }))
        .catch((err) => { throw err; })
        .finally(() => {
          runInAction('SetIsSubmitingFalse', () => {
            this.isSubmiting = false;
          });
        });
    default:
      return InspectionService.saveInspection(inspectionId, formCompleted)
        .finally(() => {
          runInAction('SetIsSubmitingFalse', () => {
            this.isSubmiting = false;
          });
        });
    }
  }

  generateReportPDF({ inspectionId, needLoader = true }) {
    const fileReader = new FileReader();

    if (needLoader) {
      this.isGeneratingReport = true;
    }

    return InspectionService.generateReportPDF(inspectionId)
      .then((response) => DocumentHelper.convertBytesToStream(response))
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => ({ fileReader, blob }))
      .finally(() => {
        runInAction('SetIsGeneratingReportFalse', () => {
          this.isGeneratingReport = false;
        });
      });
  }

  generateReportPDFFromExcel({ inspectionId, excel, needLoader = true }) {
    const fileReader = new FileReader();

    if (needLoader) {
      this.isGeneratingReportFromExcel = true;
    }

    return InspectionService.generateReportPDFFromExcel(inspectionId, excel)
      .then((response) => DocumentHelper.convertBytesToStream(response))
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => ({ fileReader, blob }))
      .finally(() => {
        runInAction('SetIsGeneratingReportFromExcelFalse', () => {
          this.isGeneratingReportFromExcel = false;
        });
      });
  }

  setInspectionStartPosition(startPosition) {
    this.startPosition = startPosition;
    return this.startPosition;
  }

  setInspectionEndPosition(endPosition) {
    this.endPosition = endPosition;
    return this.endPosition;
  }
}

decorate(InspectionStore, {
  isLoading: observable,
  isLoadingForm: observable,
  isDownloading: observable,
  isSubmiting: observable,
  isGeneratingReport: observable,
  isGeneratingReportFromExcel: observable,
  inspection: observable,
  inspectionForm: observable,
  inspectionId: observable,
  folderId: observable,
  inspectionList: observable,
  inspectionListStored: observable,
  isInspectionSaved: observable,
  maxPage: observable,
  startPosition: observable,
  endPosition: observable,
  reloadKey: observable,
  QUESTION_KEY: action,
  checkInspectionSaved: action,
  getInspectionsToSubmit: action,
  loadInspectionList: action,
  loadInspectionStored: action,
  loadInspection: action,
  loadInspectionForm: action,
  updateInspection: action,
  downloadInspectionOffline: action,
  saveInspectionFormOffline: action,
  saveQuestion: action,
  saveInspectionFormCurrentStep: action,
  getInspectionFormCurrentStep: action,
  getSectionQuestions: action,
  getQuestion: action,
  deleteInspection: action,
  deleteSubmittedInspection: action,
  submitInspection: action,
  reSubmitInspection: action,
  generateReportPDF: action,
  generateReportPDFFromExcel: action,
  setInspectionStartPosition: action,
  setInspectionEndPosition: action,
  saveInspectionTimestampAndPosition: action,
  setReloadKey: action,
  resetReloadKey: action
});

export const inspectionStore = new InspectionStore();