import i18n from 'i18next';
import { userStore } from 'stores';
import { translate } from 'utils/index';
import { FilterHelper } from '.';

import { httpSettings } from './request';

const getErrorTranslation = (code, message) => {
  let error = String(message);
  if (error.indexOf(':') !== -1) {
    error = error.split(':')[1].trim();
  }

  if (i18n.exists(`errors.${error}`)) {
    return translate(`errors.${error}`);
  }

  return `${translate('errors.UNCATCHED_ERROR')} (${translate('errors.error')} ${code})`;
};
export class HTTPError extends Error {
  constructor(code, message) {
    super(getErrorTranslation(code, message));
    this.statusCode = code;
  }
}

const defaultResponseHandler = async (response) => {
  const { status, statusText } = response;

  switch (status) {
  case 200:
    return response.json();
  case 201:
  case 202:
  case 204:
    return true;
  case 226:
    // Specific case for files
    return response;
  case 401:
    if (userStore.isConnected) {
      userStore.refreshToken()
        .then(() => setTimeout(() => window.location.reload(), 2000))
        .catch(() => userStore.disconnectUser()
          .then(() => userStore.keycloakLogin()));

      throw new Error("Votre session vient d'être renouvellée. La page va se recharger et vous pourrez réitérer votre action.");
    }

    FilterHelper.deleteStoredFilters();
    userStore.keycloakLogin();
    throw new Error('Votre session a expiré. Vous allez être redirigé vers la page de connexion.');
  case 412:
    return response.json().then((error) => {
      throw error;
    });
  default:
    throw new HTTPError(status, statusText);
  }
};

const defaultErrorHandler = (error) => {
  throw error;
};

const GET = (url, headers) => fetch(url, httpSettings({
  headers,
  method: 'GET',
  credentials: 'include'
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const POST = (url, body = {}, headers = null) => fetch(url, httpSettings({
  method: 'POST',
  headers,
  credentials: 'include',
  body
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const PUT = (url, body = {}, headers = null) => fetch(url, httpSettings({
  method: 'PUT',
  headers,
  credentials: 'include',
  body
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const PATCH = (url, body = {}, headers = null) => fetch(url, httpSettings({
  method: 'PATCH',
  headers,
  credentials: 'include',
  body
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const DELETE = (url, body = {}, headers = null) => fetch(url, httpSettings({
  method: 'DELETE',
  headers,
  credentials: 'include',
  body
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

// Regroup all functions inside a single const to simplify
export const RequestHelper = {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE
};