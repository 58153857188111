import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { translate } from 'utils';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  .MuiToggleButton-root:first-child.Mui-selected {
    background-color: var(--primary-color);
  }

  .MuiToggleButton-root:nth-child(2).Mui-selected {
    background-color: var(--danger-color);
  }
`;

const ToggleDeclaredValues = observer(({
  name, value, declared, onChange,
  declaredValueAccepted, setDeclaredValueAccepted
}) => {
  const handleChangeDeclaredValueToggle = useCallback((_, isDeclared) => {
    if (isDeclared === null) return;

    setDeclaredValueAccepted(isDeclared);

    // eslint-disable-next-line no-nested-ternary
    const newValue = isDeclared ? (typeof declared === 'string' ? declared : { ...declared }) : value;

    onChange(name, newValue, {
      isDeclaredSet: isDeclared
    });
  }, [onChange, name, value, declared, setDeclaredValueAccepted]);

  return (
    <StyledToggleButtonGroup
      aria-label={translate('pageInspectionForm.declaredValues')}
      exclusive
      name="declaredValueAccepted"
      size="small"
      value={declaredValueAccepted}
      onChange={handleChangeDeclaredValueToggle}
    >
      <ToggleButton aria-label={translate('button.accept')} data-testid="button.accept" disabled={declaredValueAccepted !== null} value>
        {translate('button.accept')}
      </ToggleButton>
      <ToggleButton aria-label={translate('button.refuse')} disabled={declaredValueAccepted !== null} value={false}>
        {translate('button.refuse')}
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
});

ToggleDeclaredValues.propTypes = {
  name: PropTypes.string.isRequired,
  declared: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.shape({})
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.shape({})
  ]).isRequired
};

export default ToggleDeclaredValues;